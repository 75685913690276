// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
const navigationModule = require('../modules/navigation');


/**
 * Init
 */

// Initialize navigation
navigationModule.initialize();
